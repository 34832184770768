var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-progress-circular',{staticClass:"d-flex mx-auto py-8 mt-8",attrs:{"size":100,"width":4,"color":"fmq_black","indeterminate":""}}):_c('div',[_c('v-breadcrumbs',{staticClass:"grey lighten-2 white--text",attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":"","outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v("Voltar")],1),_c('div',{staticClass:"d-flex justify-end align-center mb-4"},[(_vm.solicitacao.statusCarta === 'Em aprovação')?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v("Aguardando aprovação")]):_vm._e(),(
              _vm.solicitacao.carta &&
              _vm.checkStatus(['Finalizado'], _vm.solicitacao.status)
            )?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Ações "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3901184508)},[_c('v-list',[(_vm.checkStatus(['Finalizado'], _vm.solicitacao.status))?_c('v-list-item',{on:{"click":function($event){return _vm.abrirPDF()}}},[_c('v-list-item-title',[_vm._v("Ver PDF da Carta")])],1):_vm._e(),(_vm.checkStatus(['Finalizado'], _vm.solicitacao.status))?_c('v-list-item',{on:{"click":function($event){_vm.dialogResposta = true}}},[_c('v-list-item-title',[_vm._v("Reenviar Resposta")])],1):_vm._e(),(
                  _vm.checkStatus(['Finalizado'], _vm.solicitacao.status) &&
                  _vm.cartaConverter
                )?_c('v-list-item',{on:{"click":function($event){_vm.dialogModelo = true}}},[_c('v-list-item-title',[_vm._v("Criar Modelo a partir da Carta")])],1):_vm._e(),(_vm.solicitacao.modelosId && _vm.modeloVizualizar)?_c('v-list-item',{on:{"click":function($event){return _vm.verModelo()}}},[_c('v-list-item-title',[_vm._v("Visualizar Modelo")])],1):_vm._e(),(_vm.solicitacao.modelosId && _vm.modeloAtualizar)?_c('v-list-item',{on:{"click":function($event){_vm.modeloUpdate = true}}},[_c('v-list-item-title',[_vm._v("Atualizar Modelo")])],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('SolicitacoesResumo',{staticClass:"mb-8",attrs:{"solicitacao":_vm.solicitacao,"listLinhas":_vm.listLinhas,"listMedicosSolicitantes":_vm.listMedicosSolicitantes}}),(_vm.solicitacao.carta && _vm.checkMesa)?[_c('SolicitacaoCartaResposta',{attrs:{"solicitacao":_vm.solicitacao,"listTags":_vm.listTags,"listCategorias":_vm.listCategorias,"listArtigos":_vm.listArtigos,"listProdutos":_vm.listProdutos,"viewOnly":true,"artigos":_vm.solicitacao.carta.artigos}})]:_vm._e()],2),_c('v-col',{attrs:{"cols":"4"}},[_c('SolicitacoesComentarios',{attrs:{"comentarios":_vm.solicitacao.comentarios}})],1)],1)],1)],1),_c('AlertConfirmation',{attrs:{"dialog":_vm.dialogResposta,"dialogMessage":"Tem certeza que deseja reenviar o email para o Médico Solicitante?"},on:{"close":function($event){_vm.dialogResposta = false},"accept":_vm.reeenviarResposta}}),_c('AlertConfirmation',{attrs:{"dialog":_vm.dialogModelo,"dialogMessage":"Tem certeza que deseja converter essa Carta Resposta em um Modelo?"},on:{"close":function($event){_vm.dialogModelo = false},"accept":_vm.converterEmModelo}}),_c('AlertSuccess',{attrs:{"dialog":_vm.success,"dialogMessage":"Ação concluída com sucesso"},on:{"close":function($event){_vm.exibir();
      _vm.success = false;}}}),_c('AlertError',{attrs:{"alertError":_vm.error,"messageError":_vm.messageError}}),_c('ModelosAtulizarModal',{attrs:{"dialog":_vm.modeloUpdate,"loading":_vm.loadingUpdate},on:{"close":function($event){_vm.modeloUpdate = false},"update":function($event){return _vm.atualizar($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }